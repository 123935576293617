














































import { Component, Vue } from "vue-property-decorator";
import NinetyNineLogo from "../base/NinetyNineLogo.vue";

@Component({
  components: {
    NinetyNineLogo,
  },
})
export default class ForgotPasswordConfirm extends Vue {
  next(): void {
    this.$router.push({ name: "login" });
  }
}
